import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { reportArchiveExecutionsArraySchema } from "./reportArchiveNormalization";
import ReportsArchiveRepository from "./reportArchiveRepository";

export interface ReportArchiveExecutions {
  id: number;
  scheduledReportId: number;
  scheduledReportInfo: string;
  scheduledReportParameters: string;
  executionTime: Date;
  reportExecutionStatusEnum: string;
  executionSuccessfull: Boolean;
  errorMessage: string;
  excelUrl: string;
  pdfUrl: string;
  excelReportName: string;
  pdfReportName: string;
  user: string;
}

export const getReportArchiveExecutionsAsync = createAsyncThunk(
  "report/reportArchiveExecutions",
  async (
    data: { queryParams: string; scheduledReportId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response =
        await reportsArchiveRepository.getReportArchiveExecutions(
          data.queryParams,
          data.scheduledReportId
        );
      const totalPages = _.get(response, Config.ARCHIVE_REPORT_TOTAL_PAGES);
      const totalElements = _.get(
        response,
        Config.ARCHIVE_REPORT_TOTAL_ELEMENS
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const content = _.get(response, Config.ARCHIVE_REPORT_RESPONSE_PATH);
      const normalizedData = normalize(
        content,
        reportArchiveExecutionsArraySchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const deleteReportExecutionAsync = createAsyncThunk(
  "report/reportArchiveExecution/delete",
  async (data: { executionsId: string }, { rejectWithValue }) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response = await reportsArchiveRepository.deleteReportExecution(
        data.executionsId
      );
      return response.status;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const resendReportExecutionAsync = createAsyncThunk(
  "report/reportArchiveExecution/resend",
  async (data: { executionsId: string }, { rejectWithValue }) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response = await reportsArchiveRepository.resendReportExecution(
        data.executionsId
      );
      return response.status;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

const reportArchiveExecutionsAdapter =
  createEntityAdapter<ReportArchiveExecutions>({
    selectId: (reportArchiveExecutions) => reportArchiveExecutions.id,
  });

export const reportArchiveExecutionsSlice = createSlice({
  name: "reportArchiveExecutions",
  initialState: reportArchiveExecutionsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalElements: 0,
    totalPages: 0,
  }),
  reducers: {
    reportArchiveExecutionsEmptyState: (state) => {
      reportArchiveExecutionsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
    removeReportExecutionById: (state, action: PayloadAction<number>) => {
      reportArchiveExecutionsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getReportArchiveExecutionsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          reportArchiveExecutionsAdapter.setAll(
            state,
            action?.payload?.reportArchiveExecutions ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getReportArchiveExecutionsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getReportArchiveExecutionsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        deleteReportExecutionAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(deleteReportExecutionAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(deleteReportExecutionAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        resendReportExecutionAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(resendReportExecutionAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(resendReportExecutionAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const reportArchiveExecutionsSelectors =
  reportArchiveExecutionsAdapter.getSelectors<RootState>(
    (state) => state.reportArchiveExecutions
  );

export const selectReportArchiveExecutionsStatusSliceStatus = (state: any) =>
  state.reportArchiveExecutions.status;
export const selectReportArchiveExecutionsStatusSliceReasonCode = (
  state: any
) => state.reportArchiveExecutions.reasonCode;
export const selectReportArchiveExecutionsStatusSlicePage = (state: any) =>
  state.reportArchiveExecutions.totalPages;
export const selectReportArchiveExecutionsStatusSlicTotalElements = (
  state: any
) => state.reportArchiveExecutions.totalElements;
export const {
  setNumberOfPages,
  setNumberOfElements,
  removeReportExecutionById,
} = reportArchiveExecutionsSlice.actions;

export default reportArchiveExecutionsSlice.reducer;
