import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Button } from "../../../ui/Button/Button";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconMinus } from "../../../ui/Icon/Line/Minus";
import { Event } from "../../event/eventsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import "./AdminNotificationRuleEventTemplate.css";
import {
  getNotificationDefaultTemplateAsync,
  notificationDefaultTemplateSelectors,
} from "./notificationDefaultTemplateSlice";
import {
  getNotificationMetadataAsync,
  NotificationMetadata,
  notificationMetadataSelectors,
} from "./notificationMetadataSlice";
import { NotificationTemplateDTO } from "./notificationRuleAggregationSlice";

interface AdminNotificationRuleEventTemplateProps {
  events: Event[];
  sms: boolean;
  templates: NotificationTemplateDTO[];
  canModify: boolean;
  responseCanBeSaved: (e: any) => any;
  responseTemplatesMap: (e: any) => any;
}

interface TemplateElement {
  name: string;
  text: string;
}

export const AdminNotificationRuleEventTemplate: React.FC<AdminNotificationRuleEventTemplateProps> =
  ({
    events,
    sms,
    templates,
    canModify,
    responseCanBeSaved,
    responseTemplatesMap,
  }) => {
    const [eventManageTextSelected, setEventManageTextSelected] =
      useState<Event>({} as Event);
    const [textsPersonalized, setTextsPersonalized] = useState<
      TemplateElement[]
    >([]);

    const [isFocused, setIsFocused] = useState(false);

    const defaultTemplates: NotificationTemplateDTO[] = useAppSelector(
      notificationDefaultTemplateSelectors.selectAll
    );

    let editableDivRef = useRef<HTMLDivElement | null>(null);

    const [preferencesContext]: [Preferences] = useContext(UserContext);

    const metadataList: NotificationMetadata[] = useAppSelector(
      notificationMetadataSelectors.selectAll
    ).filter((e) =>
      e.eventTypes?.find((el) => el === eventManageTextSelected?.name)
    );

    //USEEFFECT REGION

    useEffect(() => {
      events && setEventManageTextSelected(events[0]);

      const handleResize = () => {
        const elemento = editableDivRef.current;
        if (elemento) {
          elemento.style.maxWidth = "3000px";
          elemento.style.maxWidth = "none";
          const larghezzaIniziale = elemento.offsetWidth + "px";
          // Imposta la larghezza massima
          elemento.style.maxWidth = larghezzaIniziale;
        }
      };

      // Aggiungo l'evento resize quando il componente viene montato
      window.addEventListener("resize", handleResize);

      // Pulisco l'evento resize quando il componente viene smontato
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    useEffect(() => {
      //creating map element for updating/creating notificaiton rule
      let textsPersonalizedResponse: Map<string, string> = new Map<
        string,
        string
      >();
      let textsPersonalizedFilteredAndSubstituted;

      textsPersonalizedFilteredAndSubstituted = textsPersonalized.filter(
        (textEvent) => {
          return (
            textEvent.name !== undefined &&
            textEvent.text !== "" &&
            textEvent.text !== undefined &&
            events?.some((event) => event.name === textEvent.name)
          );
        }
      );

      responseCanBeSaved(
        (textsPersonalizedFilteredAndSubstituted.length > 0 &&
          events.length > 0) ||
          events.length === 0
      );

      textsPersonalizedFilteredAndSubstituted =
        textsPersonalizedFilteredAndSubstituted.map(
          (textPersonalized: TemplateElement) => {
            // Itera su metadataList e applica le sostituzioni
            const newText = metadataList.reduce((updatedText, metadata) => {
              const translation =
                preferencesContext.language === "it"
                  ? metadata.italianTranslation
                  : metadata.englishTranslation;

              // Sostituisci la traduzione nel testo originale
              return updatedText.replace(
                translation,
                " " + `${metadata.originType}.${metadata.name}` + " "
              );
            }, textPersonalized.text);

            // Restituisci l'intero oggetto textPersonalized con il testo aggiornato
            return {
              ...textPersonalized,
              text: newText,
            };
          }
        );

      textsPersonalizedResponse = new Map<string, string>(
        textsPersonalizedFilteredAndSubstituted.map((element) => [
          element.name,
          element.text
            .replace(/<[^>]*>([^<]*)<\/[^>]*>/g, " $1 ")
            .replace(/\s+/g, " ")
            .trim(),
        ])
      );

      responseTemplatesMap(textsPersonalizedResponse);
    }, [textsPersonalized, eventManageTextSelected]);

    useEffect(() => {
      if (
        metadataList.length === 0 &&
        eventManageTextSelected?.name !== undefined
      ) {
        store.dispatch(
          getNotificationMetadataAsync({
            queryParams: "?EVENT=" + eventManageTextSelected?.name,
          })
        );
      }
      if (
        !defaultTemplates.find(
          (defaultTemplate) =>
            defaultTemplate.event === eventManageTextSelected?.name &&
            defaultTemplate.type === "TEXT"
        )
      ) {
        eventManageTextSelected?.name !== undefined &&
          store.dispatch(
            getNotificationDefaultTemplateAsync({
              type: eventManageTextSelected?.name,
            })
          );
      }

      if (
        !textsPersonalized?.find(
          (e) => e.name === eventManageTextSelected?.name
        )
      ) {
        let renderedTemplate =
          templates?.find(
            (e) =>
              e.event === eventManageTextSelected?.name &&
              e.template !== "" &&
              e.type == "TEXT"
          )?.template ?? "";

        if (renderedTemplate === "") {
          renderedTemplate =
            defaultTemplates.find(
              (defaultTemplate) =>
                defaultTemplate.event === eventManageTextSelected?.name &&
                defaultTemplate.type === "TEXT"
            )?.template ?? "";
        }

        if (renderedTemplate.length > 0) {
          metadataList.map((metadata) => {
            renderedTemplate = renderedTemplate.replace(
              metadata.originType + "." + metadata.name,
              renderToString(
                <span className="metadata-tag" contentEditable={false}>
                  {preferencesContext.language === "it"
                    ? metadata.italianTranslation
                    : metadata.englishTranslation}
                </span>
              )
            );
          });
          const newTemplateElement: TemplateElement = {
            name: eventManageTextSelected?.name,
            text: renderedTemplate,
          };
          textsPersonalized.push(newTemplateElement);
        }
      }
      if (editableDivRef.current) {
        editableDivRef.current.innerHTML =
          textsPersonalized?.find(
            (e) => e.name === eventManageTextSelected?.name
          )?.text ?? "";
      }
    }, [eventManageTextSelected, defaultTemplates]);

    //USEEFFECT ENDREGION

    //FUNCTION REGION

    function smsNumberCalculator(text: string) {
      let textWithoutVariables = text.replace(/\b\S*@\S*\b/g, "").trim();

      if (
        (textWithoutVariables && textWithoutVariables.length <= 160) ||
        textWithoutVariables == ""
      ) {
        return 1;
      } else {
        if (textWithoutVariables)
          return Math.ceil(textWithoutVariables.length / 153);
      }
    }

    function textTagInsert(tagText: string, elemID: string) {
      let div = document.getElementById(elemID);
      if (div !== null && div.isContentEditable) {
        const selection = window.getSelection();

        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);

          let currentNode: Node | null = selection?.rangeCount
            ? selection.getRangeAt(0).startContainer
            : null;

          // Risali l'albero dei nodi fino a trovare un div, o null se non viene trovato
          const parentDiv = currentNode
            ? (() => {
                while (currentNode && currentNode.nodeName !== "DIV") {
                  currentNode = currentNode.parentNode;
                }
                return currentNode;
              })()
            : (null as any);

          if (parentDiv?.className === elemID) {
            // Rimuove il contenuto selezionato
            range.deleteContents();

            // Crea un elemento span e imposta il suo contenuto HTML
            const tagNode = document.createElement("span");
            tagNode.className = "metadata-tag";
            tagNode.contentEditable = "false";
            tagNode.innerHTML = tagText;

            // Inserisci il nodo nel range corrente
            range.insertNode(tagNode);

            // Sposta il cursore dopo il tag inserito
            range.setStartAfter(tagNode);
            selection.removeAllRanges();
            selection.addRange(range);

            // Aggiorna il testo personalizzato
            let newContent = div.innerHTML;

            let element = textsPersonalized?.find(
              (e) => e.name === eventManageTextSelected?.name
            );

            if (element) {
              element.text = newContent;
            }

            setTextsPersonalized(
              textsPersonalized.map((e) =>
                e.name === eventManageTextSelected?.name
                  ? { ...e, text: newContent }
                  : e
              )
            );
          }
        }
      }
    }

    function textTagRemove(tagText: string, elemID: string) {
      let div = document.getElementById(elemID);
      if (div !== null) {
        // Seleziona tutti gli elementi span con la classe "metadata-tag"
        const tags = div.querySelectorAll(`span.metadata-tag`);

        tags.forEach((tag) => {
          // Controlla se l'elemento corrente ha il testo specificato
          if (tag.textContent === tagText) {
            // Rimuove l'elemento dal DOM
            tag.parentNode?.removeChild(tag);
          }
        });

        // Aggiorna il testo personalizzato
        let newContent = div.innerHTML;

        let element = textsPersonalized?.find(
          (e) => e.name === eventManageTextSelected?.name
        );

        if (element) {
          element.text = newContent;
        }

        setTextsPersonalized(
          textsPersonalized.map((e) =>
            e.name === eventManageTextSelected?.name
              ? { ...e, text: newContent }
              : e
          )
        );
      }
    }

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    //FUNCTION END REGION

    return (
      <div className="admin-notification-forms-manage-text">
        <div className="admin-notification-forms-event-selection">
          {events?.map((event: Event) => {
            return (
              <div
                className={
                  _.isEqual(event, eventManageTextSelected)
                    ? "admin-notification-forms-event-selected"
                    : "admin-notification-forms-event"
                }
                onClick={() => setEventManageTextSelected(event)}
              >
                {t("eventType." + event.name) !== "eventType." + event.name
                  ? t("eventType." + event.name)
                  : event.name
                  ? event.name.split("_").join(" ").toLowerCase()
                  : ""}
              </div>
            );
          })}
        </div>
        <div className="admin-notification-divider" />
        {!_.isEmpty(eventManageTextSelected) && (
          <div className="admin-notification-forms-text-manager-components">
            <div
              className="admin-notification-forms-text-manager-component"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <p className="admin-notification-forms-text-manager-description">
                <span style={{ fontWeight: 500 }}>
                  {t("admin.notifications.actions.manageText.description")
                    .split(" ")
                    .slice(0, 3)
                    .join(" ")}
                </span>
                {" " +
                  t("admin.notifications.actions.manageText.description")
                    .split(" ")
                    .slice(3)
                    .join(" ")}
              </p>
              <span style={{ marginBottom: "40px", marginRight: "77px" }}>
                <Button
                  size={"small"}
                  label={undefined}
                  aspect="outline"
                  onClick={() => {
                    let tempText = defaultTemplates?.find(
                      (e) => e.event === eventManageTextSelected?.name
                    )?.template;
                    metadataList.map((metadata) => {
                      tempText = tempText?.replace(
                        metadata.originType + "." + metadata.name,
                        renderToString(
                          <span
                            className="metadata-tag"
                            contentEditable={false}
                          >
                            {preferencesContext.language === "it"
                              ? metadata.italianTranslation
                              : metadata.englishTranslation}
                          </span>
                        )
                      );
                    });

                    const newTemplateElement: TemplateElement = {
                      name: eventManageTextSelected?.name,
                      text: tempText ?? "",
                    };
                    setTextsPersonalized([
                      ...textsPersonalized.filter(
                        (e) => e.name !== eventManageTextSelected?.name
                      ),
                      newTemplateElement,
                    ]);
                    if (editableDivRef.current !== null) {
                      editableDivRef.current.innerHTML = tempText ?? "";
                    }
                  }}
                  disabled={
                    !!!defaultTemplates.find(
                      (defaultTemplate) =>
                        defaultTemplate.event ===
                          eventManageTextSelected?.name &&
                        defaultTemplate.type === "TEXT"
                    ) ||
                    defaultTemplates.find(
                      (defaultTemplate) =>
                        defaultTemplate.event ===
                          eventManageTextSelected?.name &&
                        defaultTemplate.type === "TEXT"
                    )?.template ===
                      textsPersonalized?.find(
                        (e) => e.name === eventManageTextSelected?.name
                      )?.text ||
                    !canModify
                  }
                >
                  {t("admin.notifications.actions.manageText.restoreDefault")}
                </Button>
              </span>
            </div>
            <div
              className="admin-notification-forms-text-manager-component"
              style={{ marginBottom: "40px" }}
            >
              <p className="admin-notification-forms-text-manager-text">
                {t("admin.notifications.actions.manageText.text")}
              </p>
              <div
                style={{
                  width: "100%",
                  marginRight: "77px",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                {sms && (
                  <p
                    style={{
                      marginLeft: "calc(100% - 55px)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {"MIN " +
                      smsNumberCalculator(
                        textsPersonalized?.find(
                          (e) => e.name === eventManageTextSelected?.name
                        )?.text ?? ""
                      ) +
                      " SMS"}
                  </p>
                )}
                <div
                  className="admin-notification-event-template-text-area"
                  ref={editableDivRef}
                  contentEditable={canModify}
                  id="admin-notification-event-template-text-area"
                  suppressContentEditableWarning={true}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onInput={(event) => {
                    eventManageTextSelected?.name !== undefined &&
                      setTextsPersonalized([
                        ...textsPersonalized.filter(
                          (e) => e.name !== eventManageTextSelected?.name
                        ),
                        {
                          name: eventManageTextSelected?.name,
                          text:
                            document.getElementById(
                              "admin-notification-event-template-text-area"
                            )?.innerHTML ?? "",
                        },
                      ]);
                  }}
                  style={{
                    fontSize: "var(--global-font-size-sm)",
                    fontFamily: "inherit",
                    padding: "10px",
                    width: "100%",
                    minHeight: "80px",
                    outline: "none",
                    boxShadow: isFocused
                      ? "1px 1px 1px 1px rgba(0.1, 0.1, 0.1, 0.1)"
                      : "none",
                    border: isFocused
                      ? "1px solid #0d6efd"
                      : "1px solid var(--global-colors-sky-dark)",
                    borderRadius: "4px",
                  }}
                />
                <div
                  style={{
                    width: "fit-content",
                    position: "absolute",
                    right: 0,
                    marginRight: "10px",
                    bottom: 5,
                    color: "#687484",
                    display: "flex",
                  }}
                >
                  {"Min"}
                  <div style={{ marginRight: "2px", marginLeft: "2px" }}>
                    {" " +
                      textsPersonalized
                        ?.find((e) => e.name === eventManageTextSelected?.name)
                        ?.text.replace(/\b\S*@\S*\b/g, "")
                        .trim().length !==
                    undefined
                      ? textsPersonalized
                          ?.find(
                            (e) => e.name === eventManageTextSelected?.name
                          )
                          ?.text.replace(/\b\S*@\S*\b/g, "")
                          .trim().length
                      : 0}
                  </div>
                  {t("validation.characters")}
                </div>
              </div>
            </div>
            <div
              className="admin-notification-forms-text-manager-component"
              style={{ alignItems: "center", gap: "60px" }}
            >
              <p className="admin-notification-forms-text-manager-text">
                {t("admin.notifications.actions.manageText.variables")}
              </p>
              <div className="admin-notification-tag-variable-container">
                {metadataList.length > 0 &&
                  metadataList.map((e) => {
                    return (
                      <div
                        className="mn-tag"
                        style={{
                          borderRadius: "4px",
                          alignItems: "center",
                          margin: "0",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                        }}
                        onClick={() => {
                          textsPersonalized
                            ?.find(
                              (e) => e.name === eventManageTextSelected?.name
                            )
                            ?.text?.includes(
                              preferencesContext.language === "it"
                                ? e.italianTranslation
                                : e.englishTranslation
                            )
                            ? textTagRemove(
                                preferencesContext.language === "it"
                                  ? e.italianTranslation
                                  : e.englishTranslation,
                                "admin-notification-event-template-text-area"
                              )
                            : textTagInsert(
                                preferencesContext.language === "it"
                                  ? e.italianTranslation
                                  : e.englishTranslation,
                                "admin-notification-event-template-text-area"
                              );
                        }}
                      >
                        <div
                          className="mn-tag--neutro"
                          style={{
                            borderRadius: "4px",
                            padding: "4px",
                            cursor: "pointer",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          {preferencesContext.language === "it"
                            ? e.italianTranslation
                            : e.englishTranslation}
                          {textsPersonalized.length &&
                          textsPersonalized
                            ?.find(
                              (e) => e.name === eventManageTextSelected?.name
                            )
                            ?.text?.includes(
                              preferencesContext.language === "it"
                                ? e.italianTranslation
                                : e.englishTranslation
                            ) ? (
                            <IconMinus size={12} color="--white" />
                          ) : (
                            <IconAdd size={12} color="--white" />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
