import HttpClient from "../../../utils/HttpClient";

export default class ReportsArchiveRepository extends HttpClient {
  constructor() {
    super(true);
  }

  deleteReportExecution(executionsId: string) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    let path =
      "/tenants/" +
      tenantId +
      "/users/" +
      userId +
      "/scheduledReports/scheduledReportExecutions/" +
      executionsId;
    return this.instance.delete(path);
  }

  resendReportExecution(executionsId: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" +
      tenantId +
      "/scheduledReports/scheduledReportExecutions/" +
      executionsId +
      "/sendEmail";
    return this.instance.post(path);
  }

  getAllReportsArchive(queryParams: string) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    let path =
      "/tenants/" +
      tenantId +
      "/users/" +
      userId +
      "/scheduledReports" +
      queryParams;
    return this.instance.get(path);
  }

  getReportArchiveExecutions(params: string, scheduledReportId: string) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    let path =
      "/tenants/" +
      tenantId +
      "/users/" +
      userId +
      "/scheduledReports/" +
      scheduledReportId +
      "/scheduledReportExecutions" +
      params;
    return this.instance.get(path);
  }
}
