import HttpClient from "../../../utils/HttpClient";

export default class NotificationDefaultTemplateRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getNotificationDefaultTemplate(queryParams: string) {
    return this.instance.get(
      "/tenants/" +
        0 +
        "/notifications/templates?type=TEXT&event=" +
        queryParams
    );
  }
}
