import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { ReportArchiveExecutions } from "./reportArchiveExecutionsSlice";
import { reportArchivesSchema } from "./reportArchiveNormalization";
import ReportsArchiveRepository from "./reportArchiveRepository";

export interface ReportArchive {
  id: number;
  name: string;
  cronExpression: string;
  startDate: Date;
  active: boolean;
  type: string;
  parameters: string;
  jobName: string;
  jobGroup: string;
  lastScheduledReportExecution: ReportArchiveExecutions;
}

export const getAllScheduledReportAsync = createAsyncThunk(
  "report/scheduledReports",
  async (data: { queryParams: string }, { rejectWithValue, dispatch }) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response = await reportsArchiveRepository.getAllReportsArchive(
        data.queryParams
      );
      const totalPages = _.get(response, Config.ARCHIVE_REPORT_TOTAL_PAGES);
      const totalElements = _.get(
        response,
        Config.ARCHIVE_REPORT_TOTAL_ELEMENS
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const content = _.get(response, Config.ARCHIVE_REPORT_RESPONSE_PATH);
      const normalizedData = normalize(content, reportArchivesSchema);

      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

const reportsArchiveAdapter = createEntityAdapter<ReportArchive>({
  selectId: (reportArchive) => reportArchive.id,
});

export const reportsArchiveSlice = createSlice({
  name: "reportsArchive",
  initialState: reportsArchiveAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    reportsArchiveEmptyState: (state) => {
      reportsArchiveAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllScheduledReportAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          reportsArchiveAdapter.setAll(
            state,
            action?.payload?.reportArchives ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getAllScheduledReportAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getAllScheduledReportAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const reportsArchiveSelectors =
  reportsArchiveAdapter.getSelectors<RootState>(
    (state) => state.reportsArchive
  );

export const selectReportArchiveStatusSliceStatus = (state: any) =>
  state.reportsArchive.status;
export const selectReportArchiveStatusSliceReasonCode = (state: any) =>
  state.reportsArchive.reasonCode;
export const selectReportArchiveStatusSlicePage = (state: any) =>
  state.reportsArchive.totalPages;
export const selectReportArchiveStatusSlicTotalElements = (state: any) =>
  state.reportsArchive.totalElements;
export const { setNumberOfPages, setNumberOfElements } =
  reportsArchiveSlice.actions;

export default reportsArchiveSlice.reducer;
