import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { vehicleSchema, vehiclesSchema } from "./vehicleNormalization";
import VehiclesStatusRepository from "./vehiclesStatusRepository";
//#region Type
export type GPSPositions = {
  latitude: number;
  longitude: number;
  gpsPositionTimestamp: string;
  address: string;
};

export const deviceStatusValues = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  NO_SIGNAL: "NO_SIGNAL",
  UNKNOWN: "UNKNOWN",
};
export type DeviceStatusType = keyof typeof deviceStatusValues;

export const statusVehicleType = {
  MOVING: "MOVING",
  STOP: "STOP",
  PARKING: "PARKING",
  UNKNOWN: "UNKNOWN",
  OFFLINE: "OFFLINE",
};
export type StatusVehicleType = keyof typeof statusVehicleType;

export interface VehicleStatus {
  id: number;
  deviceStatus: DeviceStatusType;
  dynamicFields?: {
    customerId: number;
    deviceId: number;
    direction: number;
    gpsPositions: GPSPositions[];
    ignitionKey: boolean;
    lastUpdate: Date;
    latitude: number;
    longitude: number;
    odometer: number;
    utilizationTime: number;
    timestamp_t3: Date;
    timestamp_t2: Date;
    timestamp: Date;
    siren: boolean;
    speed: number;
    engineLock: boolean;
    address: string;
    fuelLevel: number;
    fuelLevelLiters: number;
    door: boolean;
    trailerTruck: boolean;
    temperature: number;
    vehicleId: number;
    vehicleStatus: StatusVehicleType;
    externalBatteryLevel: number;
    internalBatteryLevel: number;
    dataSource: string;
    fix: boolean;
    hdop: number;
    pdop: number;
    gsm: number;
    satellites: number;
    utilizationMinutes: number;
  };
}
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getVehiclesAsync = createAsyncThunk(
  "vehicles/getVehicles",
  async (_data, { rejectWithValue }) => {
    try {
      const vehiclesStatusRepository = new VehiclesStatusRepository();
      const response = await vehiclesStatusRepository.getVehicles();
      // The value we return becomes the `fulfilled` action payload
      const vehicles = _.get(response, Config.VEHICLES_STATUS_RESPONSE_PATH);
      const normalizedData =
        vehicles !== undefined
          ? normalize(vehicles, vehiclesSchema)
          : normalize([], vehiclesSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getVehiclesStatusByFleetAsync = createAsyncThunk(
  "vehicles/getVehiclesStatusByFleetAsync",
  async (fleetId: number, { rejectWithValue }) => {
    try {
      const vehiclesStatusRepository = new VehiclesStatusRepository();
      const response =
        await vehiclesStatusRepository.getVehiclesStatusByFleetId(fleetId);
      // The value we return becomes the `fulfilled` action payload
      const vehicles = _.get(response, Config.VEHICLES_STATUS_RESPONSE_PATH);
      const normalizedData =
        vehicles !== undefined
          ? normalize(vehicles, vehiclesSchema)
          : normalize([], vehiclesSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getVehicleAsync = createAsyncThunk(
  "vehicles/getVehicle",
  async (data: { id: number; fleetId: number }, { rejectWithValue }) => {
    try {
      const vehiclesStatusRepository = new VehiclesStatusRepository();
      const response = await vehiclesStatusRepository.getVehicle(
        data.fleetId,
        data.id
      );
      // The value we return becomes the `fulfilled` action payload
      const vehicle = _.get(response, Config.VEHICLE_STATUS_RESPONSE_PATH);
      const normalizedData = normalize(vehicle, vehicleSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getFilteredVehiclesStatusAsync = createAsyncThunk(
  "vehicles/getFilteredVehiclesStatus",
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const vehiclesStatusRepository = new VehiclesStatusRepository();
      const response = await vehiclesStatusRepository.getFilteredVehiclesStatus(
        queryParams
      );
      const vehicles = _.get(response, Config.VEHICLES_STATUS_RESPONSE_PATH);
      if (vehicles) {
        const normalizedData = normalize(vehicles, vehiclesSchema);
        return normalizedData.entities;
      } else {
        return [];
      }
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getFilteredVehiclesStatusPaginationAsync = createAsyncThunk(
  "vehicles/getFilteredVehiclesStatusPagination",
  async (data: { queryParams?: string }, { rejectWithValue, dispatch }) => {
    try {
      const vehiclesStatusRepository = new VehiclesStatusRepository();
      const response = await vehiclesStatusRepository.getFilteredVehiclesStatus(
        data.queryParams
      );
      const vehicles = _.get(response, Config.VEHICLES_STATUS_RESPONSE_PATH);
      const totalPages = _.get(
        response,
        Config.VEHICLE_STATUS_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.VEHICLE_STATUS_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      if (vehicles) {
        const normalizedData = normalize(vehicles, vehiclesSchema);
        return normalizedData.entities;
      } else {
        return [];
      }
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion API

//#region Slice
const vehiclesStatusAdapter = createEntityAdapter<VehicleStatus>({
  selectId: (vehicleStatus) => vehicleStatus.id,
});

export const vehiclesStatusSlice = createSlice({
  name: "vehiclesStatus",
  initialState: vehiclesStatusAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    vehiclesStatusEmptyState: (state: any) => {
      vehiclesStatusAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    upsertVehicleWithDelta: (state, action: PayloadAction<VehicleStatus>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const vehicleStatus = vehiclesStatusAdapter
        .getSelectors()
        .selectById(state, action.payload.id);
      if (
        action.payload.dynamicFields?.gpsPositions &&
        vehicleStatus?.dynamicFields?.gpsPositions
      ) {
        action.payload.dynamicFields.gpsPositions = [
          ...vehicleStatus.dynamicFields.gpsPositions,
          ...action.payload.dynamicFields.gpsPositions,
        ];
      }
      vehiclesStatusAdapter.upsertOne(state, action.payload);
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehiclesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehiclesStatusAdapter.upsertMany(
            state,
            action.payload.vehicleStatus ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getVehiclesAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getVehiclesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehiclesStatusAdapter.upsertMany(state, action.payload.vehicleStatus);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getVehicleAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getVehicleAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getFilteredVehiclesStatusAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFilteredVehiclesStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicleStatus
            ? vehiclesStatusAdapter.setAll(state, action.payload.vehicleStatus)
            : vehiclesStatusAdapter.setAll(state, []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredVehiclesStatusAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        getFilteredVehiclesStatusPaginationAsync.pending,
        (state: any) => {
          state.status = "loading";
        }
      )
      .addCase(
        getFilteredVehiclesStatusPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicleStatus
            ? vehiclesStatusAdapter.setAll(state, action.payload.vehicleStatus)
            : vehiclesStatusAdapter.setAll(state, []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredVehiclesStatusPaginationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getVehiclesStatusByFleetAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getVehiclesStatusByFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicleStatus &&
            vehiclesStatusAdapter.upsertMany(
              state,
              action.payload.vehicleStatus
            );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getVehiclesStatusByFleetAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      );
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const vehiclesStatusSelectors =
  vehiclesStatusAdapter.getSelectors<RootState>(
    (state) => state.vehiclesStatus
  );

export const selectVehiclesStatusSliceStatus = (state: any) =>
  state.vehiclesStatus.status;
export const selectVehiclesStatusSliceReasonCode = (state: any) =>
  state.vehiclesStatus.reasonCode;
export const selectVehiclesStatusSlicePage = (state: any) =>
  state.vehiclesStatus.totalPages;
export const selectVehiclesStatusSlicTotalElements = (state: any) =>
  state.vehiclesStatus.totalElements;
export const {
  upsertVehicleWithDelta,
  setNumberOfPages,
  setNumberOfElements,
  vehiclesStatusEmptyState,
} = vehiclesStatusSlice.actions;

//#endregion Status

export default vehiclesStatusSlice.reducer;
